import CartAgent from "../agents/CartAgent";
import {createEffect, createResource, createSignal} from "solid-js";

export function Cart (store) {

    // Agent
    const agent = new CartAgent(store);

    // Init
    store.on('@init', (state) => ({
        agents: {
            ...state.agents,
            cart: agent,
        },
        loading: {
            ...state.loading,
            cart: true
        },
        cart: null,
        addToCartResult: null,
        paymentMethods: {},
        shippingMethods: {},
        lastOrder: null,
    }));

    // Actions
    const [getCurrent, setGetCurrent] = createSignal(false);
    const [current, { refetch: refetchCurrent }] = createResource(getCurrent, agent.getCurrent.bind(agent));
    createEffect(() => current.loading && store.dispatch("setCartLoading", current.loading));
    createEffect(() => store.dispatch("setCart", current()));
    store.on('setCartLoading', (state, cart) => ({loading:{...state.loading, cart}}))

    store.on('loadCart', async (state, params) => {
        setGetCurrent(state.token);
        if (params && params.refetch) {
            refetchCurrent();
        }
    });

    store.on('setCart', (state, cart) => {
        store.dispatch("setCartLoading", false);
        return { cart };
    });

    store.on('addToCart', async (state, data) => {
        // On repasse en mise au panier si nécessaire
        if (state.cart && state.cart.state !== 'AddingItems') {
            await agent.transitionOrderToCart();
        }
        store.dispatch("setAddToCartResult", await agent.addToCart(data));
        await refetchCurrent();
    });
    store.on('setAddToCartResult', (state, addToCartResult) => ({ addToCartResult }));

    store.on('updateCart', async (state, data) => {
        // On repasse en mise au panier si nécessaire
        if (state.cart && state.cart.state !== 'AddingItems') {
            await agent.transitionOrderToCart();
        }
        await agent.updateCart(data);
        await refetchCurrent();
    });
    store.on('setAddToCartResult', (state, addToCartResult) => ({ addToCartResult }));

    store.on('removeFromCart', async (state, orderLineId) => {
        // On repasse en mise au panier si nécessaire
        if (state.cart && state.cart.state !== 'AddingItems') {
            await agent.transitionOrderToCart();
        }
        await agent.removeFromCart(orderLineId);
        await refetchCurrent();
    });

    const [getPaymentMethods, setGetPaymentMethods] = createSignal(false);
    const [paymentMethods, { refetch: refetchPaymentMethods }] = createResource(getPaymentMethods, agent.getPaymentMethods.bind(agent));
    createEffect(() => paymentMethods() && store.dispatch("setPaymentMethods", paymentMethods()));

    store.on('loadPaymentMethods', async (state, params) => {
        setGetPaymentMethods(state.token);
        if (params && params.refetch) {
            refetchPaymentMethods();
        }
    });

    store.on("setPaymentMethods", (state, methods) => ({
        paymentMethods: {
            ...state.paymentMethods,
            [state.token]: methods.filter(method => method.isEligible)
        }
    }));

    const [getShippingMethods, setGetShippingMethods] = createSignal(false);
    const [shippingMethods, { refetch: refetchShippingMethods }] = createResource(getShippingMethods, agent.getShippingMethods.bind(agent));
    createEffect(() => shippingMethods() && store.dispatch("setShippingMethods", shippingMethods()));

    store.on('loadShippingMethods', async (state, params) => {
        setGetShippingMethods(state.token);
        if (params && params.refetch) {
            refetchShippingMethods();
        }
    });

    store.on("setShippingMethods", (state, methods) => ({
        shippingMethods: {
            ...state.shippingMethods,
            [state.token]: methods
        }
    }));

    store.on("setLastOrder", (state) => ({
        lastOrder: {
            code: state.cart.code,
            customer: state.cart.customer,
            totalWithTax: state.cart.totalWithTax
        }
    }));

}

import OrdersAgent from "../agents/OrdersAgent";
import {createEffect, createResource, createSignal} from "solid-js";

export function Orders(store) {

    // Agent
    const agent = new OrdersAgent(store);

    // Init
    store.on('@init', (state) => ({
        agents: {
            ...state.agents,
            orders: agent,
        },
        loading: {
            ...state.loading,
            orders: true
        },
        orders: {}
    }));

    // Actions
    const [getOne, setGetOne] = createSignal(false);
    const [byOrderCode] = createResource(getOne, async (orderCode) => [orderCode, await agent.getOrder(orderCode)]);
    createEffect(() => byOrderCode() && store.dispatch("setOrder", byOrderCode()));
    createEffect(() => store.dispatch("setOrdersLoading", byOrderCode.loading));
    store.on('setOrdersLoading', (state, orders) => ({loading:{...state.loading, orders}}))

    store.on('loadOrder', (state, orderCode) => {setGetOne(orderCode)});
    store.on('setOrder', (state, [orderCode, order]) => ({ orders: { ...state.orders, [orderCode]: order } }));

}

import gql from 'nanographql';
import Agent from "./Agent";

export default class ActivitiesAgent extends Agent {

    async getOne(activityId) {
        if (!activityId) {
            return {};
        }
        const query = gql`
                query($id: ID!) {
                    product (id: $id) {
                        id
                        name
                        variants {
                            id
                        }
                    }
                }`;

        return this.send(query, {
            id: activityId
        }, "product");
    }

    async getAll() {
        const query = gql`
                query {  
                    products {
                        items {
                            id
                            name
                            featuredAsset {
                                source
                            }
                            variants {
                                id
                                priceWithTax
                            }
                        }
                    }
                }`;
        return this.send(query, null, ["products", "items"]);
    }

}

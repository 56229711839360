import gql from 'nanographql';
import Agent from "./Agent";

export default class AvailabilitiesAgent extends Agent {

    async getByActivity(activityId) {
        if (!activityId) {
            return [];
        }

        const query = gql`
                query($id: ID!) {
                    availabilities (productId: $id) {
                        schedule { 
                            id
                            utcOffset 
                        }
                        date
                        available
                    }
                }`;
        return this.send(query, {
            id: activityId
        }, "availabilities");
    }

}

import {Button} from "solid-bootstrap";
import moment from "moment";
import {faTrash, faWarning} from "@fortawesome/free-solid-svg-icons";
import FontAwesomeIcon from "../fontawesome/FontAwesomeIcon";
import {useStoreon} from "@storeon/solidjs";
import { createMemo, createEffect } from "solid-js";
import QuantityInput from "../QuantityInput";

export default props => {
    const [state, dispatch] = useStoreon();

    const validLines = createMemo(() => state.cart.lines.filter(line => line.customFields.isValid === true));
    const invalidLines = createMemo(() => state.cart.lines.filter(line => line.customFields.isValid === false));

    const CartLine = (props) => {

        dispatch("loadAvailabilities", props.line.productVariant.product.id);
        const available = createMemo(() => {
            if (props.line && state.availabilities[props.line.productVariant.product.id]) {
                for (const availability of state.availabilities[props.line.productVariant.product.id]) {
                    if (moment.utc(availability.date).isSame(props.line.customFields.date)) {
                        return availability.available;
                    }
                }
            }
            return 0;
        });

        const itemClasses = "cart-item" + (props.order ? " my-4" : " my-2 border-top") + (props.line.customFields.isValid === false ? " invalid" : "");
        const imageClasses = "image " + (props.order ? "position-absolute" : "");
        const detailClasses = "detail py-1 d-flex align-items-center flex-fill " + (props.order ? "order" : "ps-4");

        const onInput = (qty) => {
            // On supprime la ligne
            if (qty === "0") {
                return props.handleDelete(props.line);
            }
            // On met à jour la ligne
            return props.handleUpdate(props.line, qty);
        }

        return (
            <div class={itemClasses}>
                <div class="d-flex position-relative">
                    <Show when={props.line.customFields.isValid === false }><FontAwesomeIcon icon={faWarning} className="position-absolute text-danger fa-2x z-1" /></Show>
                    <div class={imageClasses} style={`background-image: url(${props.line.featuredAsset ? props.line.featuredAsset.source : "/images/logo_ccpl.jpg"}); background-size: ${props.line.featuredAsset ? 'cover' : '90%'}`}></div>
                    <div class={detailClasses}>
                        <div class="w-100">
                            <h6>{props.line.productVariant.name}</h6>
                            <div>{moment(props.line.customFields.date).utcOffset(props.line.customFields.schedule.utcOffset).locale('fr').format("DD/MM/YYYY \\à HH:mm")}</div>
                            <Show when={!props.invalid} fallback={`${props.line.quantity} place${props.line.quantity > 1 ? 's' : ''}`}>
                                <div class="d-flex align-items-center">
                                    <QuantityInput labelClassName="m-0 me-1" size="sm" onInput={onInput} quantity={props.line.quantity} available={available()} inputStyle="width:auto;flex:0;min-width:3rem" />
                                </div>
                            </Show>
                        </div>
                        <Button variant="link" className="align-self-start" onClick={() => props.handleDelete(props.line)}><FontAwesomeIcon icon={faTrash} /></Button>
                    </div>
                </div>
            </div>
        );

    };

    return (
        <Suspense fallback={<div class="cart-list">Chargement des lignes...</div>}>
            <div class="cart-list flex-fill">
                <For
                    each={validLines()}
                    fallback="Votre panier est vide."
                >
                    {(line) => (<CartLine line={line} handleUpdate={props.handleUpdate} handleDelete={props.handleDelete} order={props.order} />)}
                </For>
                <Show when={invalidLines().length > 0}>
                    <hr />
                    <h6>Produits non disponibles :</h6>
                    <For
                    each={invalidLines()}
                >
                    {(line) => (<CartLine line={line} handleDelete={props.handleDelete} order={props.order} invalid={true} />)}
                </For>
                </Show>
            </div>
        </Suspense>
    );

}

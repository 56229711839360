import gql from 'nanographql';
import Agent from "./Agent";

export default class UsersAgent extends Agent {

    async current() {

        const query = gql`
                query {
                    activeCustomer {
                        id
                        title
                        firstName
                        lastName
                        emailAddress
                        addresses {
                            fullName
                            streetLine1
                            streetLine2
                            city
                            province
                            postalCode
                            country {
                                id
                                code
                            }
                            phoneNumber
                            defaultShippingAddress
                            defaultBillingAddress
                        }
                        user {
                            id
                            identifier
                            verified
                        }
                    }
                }`;

        return this.send(query, {}, "activeCustomer");
    }

    async login(username, password) {
        const query = gql`
                mutation Login($username: String!, $password: String!) {
                      login(username: $username, password: $password, rememberMe: true) {
                            ...on CurrentUser {
                                id
                            }
                            ...on ErrorResult {
                                errorCode
                                message
                            }
                      }
                }`;

        return this.send(query, {
            username,
            password
        }, "login");
    }

    async logout() {
        const query = gql`
                mutation Logout {
                      logout {
                            success
                      }
                }`;

        return this.send(query, {}, "logout");
    }

    async checkEmail(email) {
        const query = gql`
                query($email: String!) {
                    checkEmail(email: $email) {
                        customerExist
                    }
                }`;

        return this.send(query, {
            email
        }, "checkEmail");
    }

    async createCustomerAddress(input) {
        console.log("createCustomerAddress");
        const query = gql`
                mutation CreateCustomerAddress($input: CreateAddressInput!) {
                      createCustomerAddress(input: $input) {
                            id
                      }
                }`;

        return this.send(query, {
            input
        }, "createCustomerAddress");
    }

    async setOrderBillingAddress(input) {
        console.log("setOrderBillingAddress");
        const query = gql`
                mutation SetOrderBillingAddress($input: CreateAddressInput!) {
                      setOrderBillingAddress(input: $input) {
                            ...on Order {
                                id
                            }
                            ...on ErrorResult {
                                errorCode
                                message
                            }
                      }
                }`;

        return this.send(query, {
            input
        }, "setOrderBillingAddress");
    }

    async setOrderShippingAddress(input) {
        console.log("setOrderShippingAddress");
        const query = gql`
                mutation SetOrderShippingAddress($input: CreateAddressInput!) {
                      setOrderShippingAddress(input: $input) {
                            ...on Order {
                                id
                            }
                            ...on ErrorResult {
                                errorCode
                                message
                            }
                      }
                }`;

        return this.send(query, {
            input
        }, "setOrderShippingAddress");
    }

    async registerCustomerAccount(input) {
        console.log("registerCustomerAccount");
        const query = gql`
                mutation RegisterCustomerAccount($input: RegisterCustomerInput!) {
                      registerCustomerAccount(input: $input) {
                            ...on Success {
                                success
                            }
                            ...on ErrorResult {
                                errorCode
                                message
                            }
                      }
                }`;

        return this.send(query, {
            input
        }, "registerCustomerAccount");
    }

    async setCustomerForOrder(input) {
        console.log("setCustomerForOrder");
        const query = gql`
                mutation SetCustomerForOrder($input: CreateCustomerInput!) {
                      setCustomerForOrder(input: $input) {
                            ...on Order {
                                id
                            }
                            ...on ErrorResult {
                                errorCode
                                message
                            }
                      }
                }`;

        return this.send(query, {
            input
        }, "setCustomerForOrder");
    }

    async verifyCustomerAccount(token, password) {
        const query = gql`
                mutation VerifyCustomerAccount($token: String!, $password: String!) {
                    verifyCustomerAccount(token: $token, password: $password) {
                            ...on CurrentUser {
                                id
                            }
                            ...on ErrorResult {
                                errorCode
                                message
                            }
                      }
                }`;

        return this.send(query, {
            token, 
            password
        }, "verifyCustomerAccount");
    }

    async requestPasswordReset(email) {
        const query = gql`
                mutation RequestPasswordReset($email: String!) {
                    requestPasswordReset(emailAddress: $email) {
                            ...on Success {
                                success
                            }
                            ...on ErrorResult {
                                errorCode
                                message
                            }
                      }
                }`;

        return this.send(query, {
            email
        }, "requestPasswordReset");
    }

    async resetPassword(token, password) {
        const query = gql`
                mutation ResetPassword($token: String!, $password: String!) {
                    resetPassword(token: $token, password: $password) {
                            ...on CurrentUser {
                                id
                            }
                            ...on ErrorResult {
                                errorCode
                                message
                            }
                      }
                }`;

        return this.send(query, {
            token, 
            password
        }, "resetPassword");
    }

}

import { render } from 'solid-js/web';
import { Router } from "@solidjs/router";
import { StoreonProvider } from '@storeon/solidjs';
import { store } from './store'
import App from './App';  

// Logrocket
/*#if _IS_PROD
import LogRocket from 'logrocket';
LogRocket.init('wif1kj/plateforme-ccpl');
//#endif */

// Styles
/*#if _ES
import "./scss/app.scss";
//#endif */

render(() => (
    <Router>
        <StoreonProvider store={store}>
            <App />
        </StoreonProvider>
    </Router>
), document.body);
import gql from 'nanographql';
import Agent from "./Agent";

export default class InstitutionsAgent extends Agent {

    async getOne(channelToken) {
        if (!channelToken) {
            return {};
        }
        const query = gql`
                query($token: String!) {
                    channel (token: $token) {
                        id
                        code
                        token
                        customFields {
                            logo { source }
                            primaryColor
                            secondaryColor
                            defaultShippingMethod { id }
                            skipShippingMethod
                        }
                    }
                }`;

        return this.send(query, {
            token: channelToken
        }, "channel");
    }

    async getAll() {
        const query = gql`
                query {  
                    channels {
                        id
                        code
                        token
                        customFields {
                            logo { source }                            
                            primaryColor
                            secondaryColor
                            defaultShippingMethod { id }
                            skipShippingMethod
                        }
                    }
                }`;
        return this.send(query, null, "channels");
    }

}

export default () => {

    return (
        <footer class="bg-primary py-4">
            <div class="container text-white">
                <div class="row">
                    <div class="col-md text-center text-md-left">CCPL - Tous droits réservés</div>
                    <div class="col-md text-center">Mentions légales</div>
                    <div class="col-md text-center text-md-right">
                        <a href="https://etd-solutions.com/?utm_source=ccpl-resa&utm_medium=clients&utm_campaign=branding" target="_blank" id="etdsolutions" class="text-white">
                            Application créée et hébergée par <span class="sr-only">l'agence web de création de site internet à Saint-Omer</span> ETD Solutions.
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
};

import {classList, normalizeIconArgs, objectWithKey, styleList} from './utils'
import convert from './converter'
import {icon, parse} from '@fortawesome/fontawesome-svg-core'
import {mergeProps} from 'solid-js';


const defaultProps = {
    border: false,
    className: '',
    mask: null,
    fixedWidth: false,
    inverse: false,
    flip: null,
    icon: null,
    listItem: false,
    pull: null,
    pulse: false,
    rotation: null,
    size: null,
    spin: false,
    symbol: false,
    title: '',
    transform: null,
    swapOpacity: false,
    primaryOpacity: null,
    secondaryOpacity: null,
    primaryColor: null,
    secondaryColor: null
};

export default function FontAwesomeIcon(props) {

    props = mergeProps(defaultProps, props);

    const {
        icon: iconArgs,
        mask: maskArgs,
        symbol,
        className,
        title,
        titleId
    } = props

    const iconLookup = normalizeIconArgs(iconArgs)

    const classes = objectWithKey('classes', [
        ...classList(props),
        ...className.split(' ')
    ])
    const styles = objectWithKey('styles', styleList(props))
    const transform = objectWithKey(
        'transform',
        typeof props.transform === 'string'
            ? parse.transform(props.transform)
            : props.transform
    )
    const mask = objectWithKey('mask', normalizeIconArgs(maskArgs))

    const renderedIcon = icon(iconLookup, {
        ...classes,
        ...transform,
        ...styles,
        ...mask,
        symbol,
        title,
        titleId
    })

    if (!renderedIcon) {
        console.error('Could not find icon', iconLookup)
        return null
    }

    const {abstract} = renderedIcon
    const extraProps = {}

    Object.keys(props).forEach(key => {
        // eslint-disable-next-line no-prototype-builtins
        if (!defaultProps.hasOwnProperty(key)) {
            extraProps[key] = props[key]
        }
    })

    return convert(abstract[0], extraProps)
}

//const convertCurry = convert.bind(null, createComponent)

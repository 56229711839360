import FontAwesomeIcon from "./fontawesome/FontAwesomeIcon";
import {faUser} from '@fortawesome/free-solid-svg-icons';
import {Dropdown, DropdownButton} from "solid-bootstrap";
import {Show, createEffect} from "solid-js";
import {useStoreon} from "@storeon/solidjs";

export default props => {
    const [state, dispatch] = useStoreon();
    createEffect(() => dispatch("pullUser"));

    return (
        <Dropdown align="end" className="ms-1">
            <Dropdown.Toggle variant="primary"><FontAwesomeIcon icon={faUser} /><span class="d-none d-md-inline"> { state.currentUser && state.currentUser.firstName }</span></Dropdown.Toggle>
            <Dropdown.Menu>
                <Show when={state.currentUser} fallback={<Dropdown.Item href="/user/login">Me connecter</Dropdown.Item>}>
                    {/*<Dropdown.Item href="/user">Mon compte</Dropdown.Item>*/}
                    <Dropdown.Item href="/user/logout">Me déconnecter</Dropdown.Item>
                </Show>
            </Dropdown.Menu>
        </Dropdown>
    );

}

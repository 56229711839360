import {camelize} from './utils'

function convert(element, extraProps = {}) {
  if (typeof element === 'string') {
    return element
  }

  const children = (element.children || []).map(child => {
    return convert(child)
  })

  /* eslint-disable dot-notation */
  const mixins = Object.keys(element.attributes || {}).reduce(
    (acc, key) => {
      const val = element.attributes[key]

      switch (key) {
        case 'class':
          acc.attrs['className'] = val
          delete element.attributes['class']
          break
        default:
          if (key.indexOf('aria-') === 0 || key.indexOf('data-') === 0) {
            acc.attrs[key.toLowerCase()] = val
          } else {
            acc.attrs[camelize(key)] = val
          }
      }

      return acc
    },
    { attrs: {} }
  )

  const { ...remaining } = extraProps

  /* eslint-enable */

  return (
      <Dynamic component={element.tag} {...mixins.attrs} {...remaining}>
          {children}
      </Dynamic>
  );

}

export default convert

// Lib
import {createEffect, Suspense, createMemo} from "solid-js";
import {Routes, Route, useLocation} from "@solidjs/router";

// Store
import {useStoreon} from '@storeon/solidjs';

// Components
import Header from "./components/Header";
import Footer from "./components/Footer";
import FontAwesomeIcon from "./components/fontawesome/FontAwesomeIcon";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import { Link } from "@solidjs/router";

// Views
import Home from "./views/Home";

/*#if _ES
import {lazy} from "solid-js";
const Institution = lazy(() => import("./views/Institution"));
const Activity = lazy(() => import("./views/Activity"));
const Booking = lazy(() => import("./views/Booking"));
const Order = lazy(() => import("./views/Order"));
const User = lazy(() => import("./views/User"));
//#else */
import Institution from "./views/Institution";
import Activity from "./views/Activity";
import Booking from "./views/Booking";
import Order from "./views/Order";
import User from "./views/User";
//#endif

export default () => {
    const [state, dispatch] = useStoreon();

    dispatch("pullUser");
    createEffect(() => state.token && dispatch("loadInstitution", state.token));

    const institution = createMemo(() => state.token && state.institutions[state.token]);
    const styles = createMemo(() => ({
        "--bs-primary": institution() && institution().customFields ? institution().customFields.primaryColor: "#50813B",
        "--bs-secondary": institution() && institution().customFields ? institution().customFields.secondaryColor: "#9C87BB"
    }));

    // Quand on est dans le process de commande.
    const location = useLocation();
    createEffect(() => dispatch("isOrder", location.pathname.startsWith("/order") && !location.pathname.startsWith("/order/confirm") && !location.pathname.startsWith("/order/cart") && location.pathname !== "/order"));

    return (
        <div style={styles()}>
            <Header institution={institution()} />
            <main id="main">
                <div></div>
                <section>
                    <Suspense fallback={<div class="container">Chargement en cours...</div>}>
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/offline" element={<div class="container">Impossible de se connecter au backoffice.</div>} />
                            <Route path="/institution/:channelToken" element={<Institution />} />
                            <Route path="/institution/:channelToken/activity/:activityId" element={<Activity />} />
                            <Route path="/booking" element={<Booking />} />
                            <Route path="/order" element={<Order />} />
                            <Route path="/order/:step" element={<Order />} />
                            <Route path="/order/:step/:orderCode" element={<Order />} />
                            <Route path="/user/:action" element={<User />} />
                            <Route path="/user/:action/:token" element={<User />} />
                            <Route path="/*" element={<div class="container"><h2>Page introuvable</h2><div class="mt-5"><Link class="btn btn-primary" href="/"><FontAwesomeIcon icon={faChevronLeft} className={'mr-2'} /> Retour</Link></div></div>} />
                        </Routes>
                    </Suspense>
                </section>
            </main>
            <Footer/>
        </div>
    );
};

import UsersAgent from "../agents/UsersAgent";
import {createEffect, createResource, createSignal} from "solid-js";
/*#if _IS_PROD
import LogRocket from 'logrocket';
//#endif */

export function Users (store) {

    // Agent
    const agent = new UsersAgent(store);

    // Init
    store.on('@init', (state) => ({
        agents: {
            ...state.agents,
            users: agent,
        },
        loading: {
            ...state.loading,
            currentUser: true
        },
        currentUser: null,
        loginResult: null
    }));

    // Actions
    const [getCurrent, setGetCurrent] = createSignal(false);
    const [current, { refetch: refetchCurrent }] = createResource(getCurrent, agent.current.bind(agent));
    createEffect(() => store.dispatch("setCurrentUser", current()));
    createEffect(() => current.loading && store.dispatch("setCurrentLoading", current.loading));
    store.on('setCurrentLoading', (state, currentUser) => ({loading:{...state.loading, currentUser}}))

    store.on('pullUser', (state, params) => {
        setGetCurrent(true);
        if (params && params.refetch) {
            refetchCurrent();
        }
    });

    store.on("setCurrentUser", (state, currentUser) => {
        /*#if _IS_PROD
        if (currentUser && currentUser.id) {
            LogRocket.identify(currentUser.id, {
                name: `${currentUser.firstName} ${currentUser.lastName}`,
                email: currentUser.emailAddress,
            });
        }
        //#endif */
        store.dispatch("setCurrentLoading", false);
        return { currentUser };
    })

    store.on("logout", async (state) => {
        store.dispatch("setCurrentUser", null);
        await agent.logout();
        store.dispatch("loadCart", { refetch: true });
    });

}

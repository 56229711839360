import AvailabilitiesAgent from "../agents/AvailabilitiesAgent";
import {createEffect, createResource, createSignal} from "solid-js";

export function Availabilities(store) {

    // Agent
    const agent = new AvailabilitiesAgent(store);

    // Init
    store.on('@init', (state) => ({
        agents: {
            ...state.agents,
            availabilities: agent,
        },
        loading: {
            ...state.loading,
            availabilities: true
        },
        availabilities: {}
    }));

    // Actions
    const [getByActivityId, setGetByActivityId] = createSignal();
    const [byActivityId] = createResource(getByActivityId, async (args) => [args, await agent.getByActivity(args)]);
    createEffect(() => byActivityId() && store.dispatch("setAvailabilities", byActivityId()));
    createEffect(() => store.dispatch("setAvailabilitiesLoading", byActivityId.loading));
    store.on('setAvailabilitiesLoading', (state, availabilities) => ({loading:{...state.loading, availabilities}}))

    store.on('loadAvailabilities', (state, activityId) => { setGetByActivityId(activityId) });

    store.on('setAvailabilities', (state, [activityId, availabilities]) => ({ availabilities: {...state.availabilities, [activityId]: availabilities} }));

}

export default class Agent {

    constructor(state) {
        this.state = state;
    }

    async send(query, data, resKey) {

        data = data || {};

        const body = query(data);
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };

        if (this.state.get().token) {
            headers["vendure-token"] = this.state.get().token;
        }

        try {
            const response = await fetch('$_APP_ROOT_URI/shop-api', {
                headers,
                method: "POST",
                body: body,
                credentials: "include",
            });
            const json = await response.json();
            if (json.errors && json.errors.length > 0) {
                json.errors.forEach(error => console.error(error));
                return Error();
            } else {
                return this._extract(json, resKey);
            }
        } catch (err) {
            if (err.message === "Failed to fetch" && window.location.pathname !== "/offline") {
                window.location.assign("/offline");
            }
            return err;
        }

    }

    _extract(json, resKey) {
        if (!resKey) {
            return json;
        }
        if (Array.isArray(resKey)) {
            let obj = json.data;
            for (let k of resKey) {
                if (obj[k]) {
                    obj = obj[k];
                }
            }
            return obj;
        }
        return json.data[resKey];
    }
}

export function Common(store) {

    store.on("@init", () => ({
        agents: {},
        loading: {},
        assetBaseURI: "$_APP_ROOT_URI/assets",
        token: null,
        booking: {
            channelToken: null,
            activityId: null,
            variantId: null,
            scheduleId: null,
            utcOffset: null,
            date: null,
            available: 0,
            price: 0
        },
        isOrder: false
    }));

    store.on("setBooking", (state, booking) => ({ booking }));
    store.on("setToken", (state, token) => ({ token }));
    store.on("isOrder", (state, isOrder) => ({ isOrder }));

}

// Icons
import FontAwesomeIcon from "../fontawesome/FontAwesomeIcon";
import {faShoppingCart} from '@fortawesome/free-solid-svg-icons';

// Bootstap
import {Button, Offcanvas} from "solid-bootstrap";

// Store
import {useStoreon} from "@storeon/solidjs";

// Solid
import {createEffect, createSignal, Show} from "solid-js";

// UI
import CartList from "./CartList";
import CartDetails from "./CartDetails";

export default props => {
    const [state, dispatch] = useStoreon();
    createEffect(() => dispatch("loadCart"));

    // Offcanvas
    const [show, setShow] = createSignal(false);
    const handleOpen = () => setShow(true);
    const handleClose = () => setShow(false);

    // Suppression d'une ligne dans le panier
    const handleDelete = async (line) => {
        // On demande la supression au panier
        await dispatch("removeFromCart", line.id);
    };

    // Mise à jour d'une ligne dans le panier
    const handleUpdate = async (line, quantity) => {
        // On demande la supression au panier
        await dispatch("updateCart", { line, quantity });
    };

    return (
        <>
            <Button onClick={handleOpen}><FontAwesomeIcon icon={faShoppingCart} /> <span class="d-none d-md-inline">Panier </span>(<Show when={state.cart} fallback={"0"}>{state.cart.totalQuantity}</Show>)</Button>
            <Offcanvas show={show()} onHide={handleClose} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Panier <em><Show when={state.cart} fallback={"Aucun produit"}>{state.cart.totalQuantity} produit{state.cart.totalQuantity > 1 ? 's' : ''}</Show></em></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Show when={state.cart && state.cart.lines.length > 0} fallback={"Votre panier est vide."}>
                        <CartList handleUpdate={handleUpdate} handleDelete={handleDelete} />
                    </Show>
                </Offcanvas.Body>
                <CartDetails handleClose={handleClose} />
            </Offcanvas>
        </>
    );

}

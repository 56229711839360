import gql from 'nanographql';
import Agent from "./Agent";

export default class OrderAgent extends Agent {

    async getOrder(code) {

        const query = gql`
            query OrderByCode($code: String!) {
                orderByCode(code: $code) {
                    id
                    code
                    customer {
                        emailAddress
                        user {
                            verified
                        }
                    }
                    customFields {
                        cancelable
                    }
                    orderPlacedAt
                    totalWithTax
                }
            }`;

        return this.send(query, {
            code
        }, "orderByCode");
    }

    async cancelOrder(code) {

        const query = gql`
            mutation CancelOrder($code: String!) {
                cancelOrder(code: $code) {
                    success
                }
            }`;

        return this.send(query, {
            code
        }, "cancelOrder");

    }

}

// Bootstrap
import {InputGroup, Form, Button} from "solid-bootstrap";

// Icons
import FontAwesomeIcon from "./fontawesome/FontAwesomeIcon";
import {faPlusCircle, faMinusCircle} from "@fortawesome/free-solid-svg-icons";

// Solid
import {createEffect, createSignal, onMount} from "solid-js";

let id = 1;
const uid = () => "qty" + (++id).toString();

export default function QuantityInput(props) {

    let [inputRef, setInputRef] = createSignal();
    createEffect(() => props.ref && typeof props.ref === "function" ? props.ref(inputRef) : null);

    const [quantity, setQuantity] = createSignal(props.quantity || 1);
    const [oldQuantity, setOldQuantity] = createSignal(props.quantity || 1);
    createEffect(() => { 
        if (quantity() !== oldQuantity() && props.onInput) {
            props.onInput(quantity());
            setOldQuantity(quantity());
        }
    });

    const minus = () => setQuantity(c => (c > 2) ? c - 1 : 1);
    const plus = () => setQuantity(c => (c < props.available) ? c + 1 : props.available);

    const validateInput = () => {
        const qtyInput = inputRef();
        let value = qtyInput.value.replace(/[^0-9]/g, '');
        if (value === '') {
            if (qtyInput.hasOwnProperty("oldValue")) {
                value = qtyInput.oldValue;
            } else {
                value = props.min || "1";
            }
        } else {
            value = parseInt(value);
            if (value < 0) {
                value = parseInt(props.min) || 1;
            } else if (value > props.available) {
                value = props.available;
            }
        }
        qtyInput.oldValue = value;
        setQuantity(value);
    }

    const id = uid();

    return (
        <>
            <Form.Label htmlFor={id} className={props.labelClassName || ""}>Quantité</Form.Label>
            <InputGroup>
                <Form.Control
                    placeholder="Quantité"
                    value={quantity()}
                    aria-valuemin={props.min || "1"}
                    aria-valuemax={props.available}
                    inputMode="numeric"
                    type="number"
                    min={props.min || "1"}
                    max={props.available}
                    step="1"
                    id={id}
                    size={props.size || "lg"}
                    onInput={validateInput}
                    onChange={props.onChange}
                    ref={setInputRef}
                    style={props.inputStyle}
                />
                <Button onClick={minus} size={props.size || "lg"}><FontAwesomeIcon icon={faMinusCircle}></FontAwesomeIcon></Button>
                <Button onClick={plus} size={props.size || "lg"}><FontAwesomeIcon icon={faPlusCircle}></FontAwesomeIcon></Button>
            </InputGroup>
        </>
    );

}
import {createStoreon} from 'storeon';
import {storeonLogger} from 'storeon/devtools';
import {Common} from "./Common";
import {Cart} from "./Cart";
import {Users} from "./Users";
import {Institutions} from "./Institutions";
import {Availabilities} from "./Availabilities";
import {Activities} from "./Activities";
import {Orders} from "./Orders";
import {createRoot} from "solid-js";

function createRootedStoreon() {
    let store;
    createRoot(() => store = createStoreon([
        Common,
        Cart,
        Users,
        Institutions,
        Availabilities,
        Activities,
        Orders,
        storeonLogger
    ]));
    return store;
}

export const store = createRootedStoreon();

import {Button} from "solid-bootstrap";
import createPrice from "../../helpers/createPrice";
import {useStoreon} from "@storeon/solidjs";
import {Show} from "solid-js";
import {useNavigate} from "@solidjs/router";

export default props => {
    const [state, dispatch] = useStoreon();
    const navigate = useNavigate();

    const containerClasses = "cart-details " + (props.order ? "bg-white px-2" : "");

    return (
        <div class={containerClasses}>
            <Show when={state.cart && state.cart.lines.length > 0}>
                <Show when={props.order}>
                    <div class="d-flex justify-content-between align-items-center py-2">
                        <strong>{state.cart.totalQuantity} produit{state.cart.totalQuantity > 1 ? "s" : ""}</strong>
                        <span>{createPrice(state.cart.totalWithTax)} €</span>
                    </div>
                </Show>
                <div class="d-flex justify-content-between align-items-center border-top py-2 fs-5">
                    <strong>Total</strong>
                    <span><Show when={state.cart} fallback={"0"}>{createPrice(state.cart.totalWithTax)}</Show> €</span>
                </div>
                <div class="d-grid py-2">
                    <Show when={!props.order}>
                        <Button variant="primary" href="/order" onClick={props.handleClose}>Valider mon panier</Button>
                    </Show>
                    <Show when={props.order && state.cart.totalQuantity > 0}>
                        <Show when={props.validate !== false}>
                            <Button variant="primary" href="/order/customer">Valider mon panier</Button>
                        </Show>
                        <Show when={props.shipping}>
                            <Button variant="primary" onClick={props.doShipping}>Passer au paiement</Button>
                        </Show>
                        <div class="text-center fs-xs mt-4">
                            <p>Paiement 100% sécurisé</p>
                            <img src="/images/creditcards.svg" class="creditcards" />
                        </div>
                    </Show>
                </div>
            </Show>
        </div>
    );

}

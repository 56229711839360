import Cart from "./cart/Cart";
import User from "./User";
import {Link, useLocation} from "@solidjs/router";
import {useStoreon} from "@storeon/solidjs";
import {Show} from "solid-js";

export default (props) => {
    const location = useLocation();
    const [state] = useStoreon();

    return (
        <header id="header" class="py-4">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <Link class="logo"
                              href={location.pathname !== "/" && props.institution ? `/institution/${props.institution.token}` : "/"}>
                            <img
                                src={location.pathname !== "/" && props.institution && props.institution.customFields && props.institution.customFields.logo ? props.institution.customFields.logo.source : "/images/logo_ccpl.jpg"}
                                alt="Logo" id="logo_ccpl"/>
                        </Link>
                    </div>
                    <div class="col d-flex justify-content-end align-items-center">
                        <Show when={!state.isOrder}>
                            <Show when={state.token}>
                                <Cart/>
                            </Show>
                            <User/>
                        </Show>
                    </div>
                </div>
            </div>
        </header>
    );
};

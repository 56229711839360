import gql from 'nanographql';
import Agent from "./Agent";

export default class CartAgent extends Agent {

    async getCurrent() {

        const query = gql`
                query {
                    activeOrder {
                        id
                        code
                        state
                        active
                        customer {
                            id
                            emailAddress
                            user {
                                id
                            }
                        }
                        shippingAddress {
                            fullName
                            streetLine1
                            streetLine2
                            city
                            postalCode
                            country
                            countryCode
                            phoneNumber
                        }
                        billingAddress {
                            fullName
                            streetLine1
                            streetLine2
                            city
                            postalCode
                            country
                            countryCode
                            phoneNumber
                        }
                        lines {
                            id
                            productVariant {
                                id
                                name
                                product {
                                    id
                                }
                            }
                            featuredAsset {
                                source
                            }
                            linePriceWithTax
                            quantity
                            customFields {
                                date
                                isValid
                                schedule {
                                    utcOffset
                                }
                            }
                        }
                        surcharges {
                            id
                            priceWithTax
                        }
                        discounts {
                            type
                            description
                            amount
                            amountWithTax
                        }
                        couponCodes
                        totalQuantity
                        totalWithTax
                    }
                }`;

        return this.send(query, {}, "activeOrder");
    }

    async getPaymentMethods() {

        const query = gql`
            query {
                eligiblePaymentMethods {
                    id
                    code
                    name
                    description
                    isEligible
                    eligibilityMessage
                }
            }`;

        return this.send(query, {}, "eligiblePaymentMethods");
    }

    async getShippingMethods() {

        const query = gql`
            query {
                eligibleShippingMethods {
                    id
                    code
                    name
                    price
                    priceWithTax
                    description
                    metadata
                }
            }`;

        return this.send(query, {}, "eligibleShippingMethods");
    }

    async createPayFipPaymentIntent(code) {

        const query = gql`
            mutation CreatePayFipPaymentIntent($code: String!) {
                createPayFipPaymentIntent(input: {
                    paymentMethodCode: $code
                }) {
                     ... on PayFipPaymentIntent {
                         url
                     }
                     ... on PayFipPaymentIntentError {
                         errorCode
                         message
                     }
                }
            }`;

        return this.send(query, {
            code
        }, "createPayFipPaymentIntent");

    }

    async validateOrder() {

        const query = gql`
            mutation ValidateOrder() {
                validateOrder {
                     ... on Order {
                         id
                     }
                     ... on ErrorResult {
                         errorCode
                         message
                     }
                }
            }`;

        return this.send(query, {}, "validateOrder");
        
    }

    async addPaymentToOrder(code) {

        const query = gql`
            mutation AddPaymentToOrder($code: String!) {
                addPaymentToOrder(input: {
                    method: $code,
                    metadata: {}
                }) {
                     ... on Order {
                         id
                     }
                     ... on ErrorResult {
                         errorCode
                         message
                     }
                }
            }`;

        return this.send(query, {
            code
        }, "addPaymentToOrder");

    }

    async createTestPaymentIntent(code) {

        const query = gql`
            mutation CreateTestPaymentIntent($code: String!, $decline: Boolean!) {
                addPaymentToOrder(input: {
                    method: $code,
                    metadata: {
                      shouldDecline: $decline,
                      shouldError: false,
                      shouldErrorOnSettle: false,
                    }
                }) {
                     ... on Order {
                         id
                     }
                     ... on ErrorResult {
                         errorCode
                         message
                     }
                }
            }`;

        return this.send(query, {
            code,
            decline: code.indexOf("refuse") !== -1
        }, "addPaymentToOrder");

    }

    async transitionOrder(state) {

        const query = gql`
            mutation TransitionOrderToPayment($state: String!) {
                transitionOrderToState(state: $state) {
                     ... on Order {
                         id
                     }
                     ... on ErrorResult {
                         errorCode
                         message
                     }
                }
            }`;

        return this.send(query, {
            state
        }, "transitionOrderToState");

    }

    async transitionOrderToPayment() {
        return this.transitionOrder("ArrangingPayment");
    }

    async transitionOrderToCart() {
        return this.transitionOrder("AddingItems");
    }

    async addToCart(data) {
        const query = gql`
                mutation AddToCart($variantId: ID!, $quantity: Int!, $customs: OrderLineCustomFieldsInput!) {
                      addItemToOrder(productVariantId: $variantId, quantity: $quantity, customFields: $customs) {
                            ...on Order {
                                id
                            }
                            ...on ErrorResult {
                                errorCode
                                message
                            }
                      }
                }`;

        return this.send(query, {
            variantId: data.booking.variantId,
            quantity: parseInt(data.quantity),
            customs: {
                scheduleId: data.booking.scheduleId,
                date: data.booking.date,
            }
        }, "addItemToOrder");

    }

    async updateCart(data) {
        const query = gql`
                mutation UpdateCart($lineId: ID!, $quantity: Int!) {
                    adjustOrderLine(orderLineId: $lineId, quantity: $quantity) {
                            ...on Order {
                                id
                            }
                            ...on ErrorResult {
                                errorCode
                                message
                            }
                      }
                }`;

        return this.send(query, {
            lineId: data.line.id,
            quantity: parseInt(data.quantity),
        }, "adjustOrderLine");

    }

    async removeFromCart(orderLineId) {
        const query = gql`
                mutation RemoveFromCart($orderLineId: ID!) {
                      removeOrderLine(orderLineId: $orderLineId) {
                            ...on Order {
                                id
                            }
                            ...on ErrorResult {
                                errorCode
                                message
                            }
                      }
                }`;

        return this.send(query, {
            orderLineId
        }, "removeOrderLine");

    }

    async createCustomerAddress(input) {
        console.log("createCustomerAddress");
        const query = gql`
                mutation CreateCustomerAddress($input: CreateAddressInput!) {
                      createCustomerAddress(input: $input) {
                            id
                      }
                }`;

        return this.send(query, {
            input
        }, "createCustomerAddress");
    }

    async setOrderBillingAddress(input) {
        console.log("setOrderBillingAddress");
        const query = gql`
                mutation SetOrderBillingAddress($input: CreateAddressInput!) {
                      setOrderBillingAddress(input: $input) {
                            ...on Order {
                                id
                            }
                            ...on ErrorResult {
                                errorCode
                                message
                            }
                      }
                }`;

        return this.send(query, {
            input
        }, "setOrderBillingAddress");
    }

    async setOrderShippingAddress(input) {
        console.log("setOrderShippingAddress");
        const query = gql`
                mutation SetOrderShippingAddress($input: CreateAddressInput!) {
                      setOrderShippingAddress(input: $input) {
                            ...on Order {
                                id
                            }
                            ...on ErrorResult {
                                errorCode
                                message
                            }
                      }
                }`;

        return this.send(query, {
            input
        }, "setOrderShippingAddress");
    }

    async registerCustomerAccount(input) {
        console.log("registerCustomerAccount");
        const query = gql`
                mutation RegisterCustomerAccount($input: RegisterCustomerInput!) {
                      registerCustomerAccount(input: $input) {
                            ...on Success {
                                success
                            }
                            ...on ErrorResult {
                                errorCode
                                message
                            }
                      }
                }`;

        return this.send(query, {
            input
        }, "createCustomerAddress");
    }

    async setCustomerForOrder(input) {
        console.log("setCustomerForOrder");
        const query = gql`
                mutation SetCustomerForOrder($input: CreateCustomerInput!) {
                      setCustomerForOrder(input: $input) {
                            ...on Order {
                                id
                            }
                            ...on ErrorResult {
                                errorCode
                                message
                            }
                      }
                }`;

        return this.send(query, {
            input
        }, "setCustomerForOrder");
    }

    async setOrderShippingMethod(id) {
        const query = gql`
                mutation SetOrderShippingMethod($shippingMethodId: ID!) {
                      setOrderShippingMethod(shippingMethodId: $shippingMethodId) {
                            ...on Order {
                                id
                            }
                            ...on ErrorResult {
                                errorCode
                                message
                            }
                      }
                }`;

        return this.send(query, {
            shippingMethodId: parseInt(id)
        }, "setOrderShippingMethod");
    }

}

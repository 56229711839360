import {createEffect} from "solid-js";
import {useStoreon} from "@storeon/solidjs";

/*#if _ES
import {lazy} from "solid-js";
const Home = lazy(() => import("./Home"));
//#else */
import Home from "./Home";
//#endif

export default function () {
    const [, dispatch] = useStoreon();

    dispatch("setToken", null);
    dispatch("loadInstitutions");

    return Home();
}

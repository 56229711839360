import InstitutionsAgent from "../agents/InstitutionsAgent";
import {createEffect, createResource, createSignal} from "solid-js";

export function Institutions(store) {

    // Agent
    const agent = new InstitutionsAgent(store);

    // Init
    store.on('@init', (state) => ({
        agents: {
            ...state.agents,
            institutions: agent,
        },
        loading: {
            ...state.loading,
            institutions: true
        },
        institutions: {}
    }));

    // Actions
    const [getAll, setGetAll] = createSignal(false);
    const [all, {refetch: refetchAll}] = createResource(getAll, agent.getAll.bind(agent));
    createEffect(() => all() && store.dispatch("setInstitutions", all()));
    createEffect(() => store.dispatch("setInstitutionsLoading", all.loading));
    store.on('setInstitutionsLoading', (state, institutions) => ({loading:{...state.loading, institutions}}))
    store.on('loadInstitutions', async (state, params) => {
        setGetAll(true);
        if (params && params.refetch) {
            await refetchAll();
        }
    });

    store.on('setInstitutions', (state, all) => {
        return {
            institutions: all.reduce((memo, institution) => {
                memo[institution.token] = institution;
                return memo;
            }, {})
        };
    });

    store.on('loadInstitution', async (state, token) => {
        if (!state.institutions[token]) {
            store.dispatch("loadInstitutions");
        }
    });

}

import ActivitiesAgent from "../agents/ActivitiesAgent";
import {createEffect, createResource, createSignal} from "solid-js";

export function Activities(store) {

    // Agent
    const agent = new ActivitiesAgent(store);

    // Init
    store.on('@init', (state) => ({
        agents: {
            ...state.agents,
            activities: agent,
        },
        loading: {
            ...state.loading,
            activities: true
        },
        activities: {}
    }));

    // Actions
    const [getAll, setGetAll] = createSignal(false);
    const [all, {refetch: refetchAll}] = createResource(getAll, agent.getAll.bind(agent));
    createEffect(() => all() && store.dispatch("setActivities", all()));
    createEffect(() => store.dispatch("setActivitiesLoading", all.loading));
    store.on('setActivitiesLoading', (state, activities) => ({loading:{...state.loading, activities}}))

    store.on('loadActivities', async (state, params) => {
        setGetAll(state.token);
        if (params && params.refetch) {
            await refetchAll();
        }
    });

    store.on('setActivities', (state, all) => {
        return {
            activities: all.reduce((memo, activity) => {
                memo[activity.id] = activity;
                return memo;
            }, {})
        };
    });

    store.on('loadActivity', async (state, id) => {
        if (!state.activities[id]) {
            store.dispatch("loadActivities", { token: state.token });
        }
    });

}
